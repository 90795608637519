import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'

import { breakpoints } from '~/utils/styles'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Product = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  position: relative;
`

export const Title = styled.span`
  font-family: 'Old Standard TT', serif;
  font-weight: 300;
  font-style: italic;
  font-size: 2rem;
  text-align: center;
  position: absolute;
  margin-top: 1rem;
  margin-left: 1rem;
  color: white;
  z-index: 2;
  text-shadow: 2px 2px 20px black;
`

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ImgAbove = styled(GatsbyImage)`
  z-index: 1;
  transition: opacity ease-in-out 0.2s;

  &:hover {
    opacity: 0;
  }
`

export const ImgBelow = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
`
