import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

import Seo from '~/components/seo'
import Navigation from '~/components/Navigation'
import Footer from '~/components/Footer'
import {
  Container,
  TwoColumnGrid,
  MainContent,
  breakpoints,
} from '~/utils/styles'
import dosenStopMotionMP4 from '~/images/dose_stop_motion_rahmen.mp4'
import ProductGrid from '../components/ProductGrid'

const HeroWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content; center;
  align-items: center;
  margin-bottom: 10rem;
`

const HeroVideo = styled.video`
  width: 50%;
  margin-bottom: 3rem;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

const H2 = styled.h2`
  font-size: 2.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'Old Standard TT', serif;
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  text-align: center;
`

const Text = styled.p`
  text-align: center;
  font-family: 'Old Standard TT', serif;
  line-height: 1.6;
  font-size: 1.2rem;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Dosen = () => (
  <>
    <Seo title="Dosen" description="Dosen" />
    <Navigation />
    <Container>
      <MainContent>
        <HeroWrapper>
          <HeroVideo muted playsInline autoPlay loop>
            <source src={dosenStopMotionMP4} type="video/mp4" />
          </HeroVideo>
          <H2>NurEinBerg Dosenaktion</H2>
          <Text>
            Dein Shirt in einer wunderschönen Lebkuchendose. Um das ganze
            nachhaltig zu gestalten, werden ausschließlich recycelte Dosen
            verwendet.
            <br /> So ist jede Bestellung eine Überraschung - welches Nürnberg
            Motiv wird wohl bei deiner Bestellung auf der Dose sein?
          </Text>
        </HeroWrapper>
        <TwoColumnGrid>
          <ImgWrapper>
            <StaticImage
              src="../images/dosen_shirt.png"
              layout="fullWidth"
              alt="Nürnberg Panorama"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </ImgWrapper>
          <TextWrapper>
            <H2>Überraschung!</H2>
            <Text>
              Unser Ziel war es, noch nachhaltiger zu versenden. Deshalb haben
              wir seit einem Jahr Lebkuchen Dosen gebraucht angekauft, um diese
              Aktion zu der Weihnachtszeit 2021 realisieren zu können.
              <br />
              <br /> Zudem ist jede Dose einzigartig und somit eine schöne
              Überraschung und perfekt als Weihnachtsgeschenk geeignet.
            </Text>
          </TextWrapper>
        </TwoColumnGrid>

        <div style={{ marginTop: '6rem', marginBottom: '6rem' }}>
          <H2>Jetzt Bestellen</H2>
          <Text>
            Die Aktion ist auf 50 Dosen limitiert und gilt im Versandgroßraum
            Nürnberg/Fürth/Erlangen.
            <br />
            Freu dich auf deine individuelle Dose und eine garantierte Lieferung
            vor Weihnachten.
          </Text>
        </div>

        <ProductGrid />
      </MainContent>
      <Footer />
    </Container>
  </>
)

export const query = graphql`
  query {
    patchDetail: file(relativePath: { eq: "Patch_Detail.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    labelWeiss: file(relativePath: { eq: "Label_weiß.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    brunnenWeiss: file(relativePath: { eq: "Brunnen_weiß.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    heinrichBaumwolle3: file(relativePath: { eq: "Heinrich_Baumwolle_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    nahaufnahmeBrunnen: file(relativePath: { eq: "Nahaufnahme_Brunnen.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 400, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }
`

export default Dosen
