import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// import StoreContext from '../../context/StoreContext'
import { Grid, Product, Title, ImgAbove, ImgBelow, ImgWrapper } from './styles'

const ProductGrid = () => {
  // const { checkout } = useContext(StoreContext)
  const { allShopifyProduct } = useStaticQuery(graphql`
    {
      allShopifyProduct {
        edges {
          node {
            title
            description
            title
            id
            handle
            images {
              id
              originalSrc
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                }
              }
            }
          }
        }
      }
    }
  `)

  /* const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0)) */

  return (
    <Grid>
      {allShopifyProduct.edges.map(({ node }) => {
        const detailImage = node.images.find(({ altText }) =>
          altText?.includes('detail')
        )

        const productImage = node.images.find(({ altText }) =>
          altText?.includes('product')
        )

        return (
          <Link key={node.id} to={`/produkt/${node.handle}/`}>
            <Product>
              <ImgWrapper>
                <ImgAbove
                  image={
                    detailImage
                      ? detailImage.localFile.childImageSharp.gatsbyImageData
                      : node.images[node.images.length - 1].localFile
                          .childImageSharp.gatsbyImageData
                  }
                  alt={node.handle}
                />
                <ImgBelow
                  src={
                    productImage
                      ? productImage.localFile.childImageSharp.gatsbyImageData
                          .images.fallback.src
                      : node.images[0].localFile.childImageSharp.gatsbyImageData
                          .images.fallback.src
                  }
                  alt={node.handle}
                />
              </ImgWrapper>
              <Title>{node.title}</Title>
            </Product>
          </Link>
        )
      })}
    </Grid>
  )
}

export default ProductGrid
